<template>
<div v-if="getAllowFullSite" class="main content-page the-prayer-room">

    <div class="content-sidebar no-sidebar-background" style="text-align: left;">
        <h1>The Prayer Room</h1>
        <p>No great move of God, from revivals to missions movements, have occurred in history without being ushered in by a prayer movement.</p>
        <p>Seeing all the lost peoples of the world, the first call to action Jesus gave was not going, but rather praying. He said in Matthew 9:37-38, <i>“The harvest is plentiful but the workers are few. Ask the Lord of the harvest, therefore, to send out workers into His harvest field.”</i> Man alone is a weak agent of change, but with God, all things are possible. We must partner with Him, seek His face, repent of our wicked ways, and bring His will on earth as it is in heaven. To see a move of God, we must first ask for a move of God.</p>
        <router-link to="/contact?interest=prayer"><button class="button-dark last-button">Ready to Connect?</button></router-link>
    </div>

    <div class="content-page-inner">

        <div v-for="(prayerPoint, index) in prayerPoints">
            <h3>{{ prayerPoint.description }}</h3>
            <div class="prayer-point" v-html="prayerPoint.main_content"></div>
        </div>
        <div class="page-controls">
            <pagination :paging="paging" @updatePage="goToPage" />
        </div>

    </div>

</div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
export default {
    name: 'the-prayer-room',
    components: {
        'pagination': Pagination
    },
    data() {
        return {
            loading: false,
            prayerPoints: [],
            paging: {},
            filters: {
                page: 1
            }
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        this.getList()
        window.scrollTo(0, 0);
    },
    methods: {
        getList() {
            this.apiCall({ destination: 'get_prayer_points', data: this.filters }).then(obj => {
                this.loading = false
                if (obj.status == 'success') {
                    this.prayerPoints = obj.prayer_points
                    this.paging = obj.paging
                }
            })
        },
    	goToPage(page) {
    		this.filters.page = page
    		this.getList()
    	}
    }
}
</script>

<style lang="scss" scoped>
.prayer-point {
    margin: 0 0 1.5em 0;
    p.caption { margin: 0 0 0 1em; }
}

</style>
